import React from "react";

const experienceContent = [
  {
    year: "2023 - Present",
    position: "AI & ML Developer",
    companyName: "University of Hull",
    details: `• Developed CityDataAgent: An LLM-based system achieving 87% accuracy in city data extraction
              • Won LLM Hackathon for innovative AI solutions
              • Developed RAG applications using LangChain and Gradio
              • Implemented vector databases (FAISS, ChromaDB) for enhanced data retrieval
              • Created web-based chat applications using Express.js and Groq API`
  },
  {
    year: "2020 - 2023",
    position: "Full Stack Developer | Data Scientist",
    companyName: "Korrect Technologies, Abuja",
    details: "Led development of data-driven solutions and ML models for business intelligence"
  },
  {
    year: "2018 - 2020",
    position: "System Administrator",
    companyName: "Soul Lounge and Suites, Kubwa, Abuja",
    details: "Managed IT infrastructure and implemented automated solutions for business processes"
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.companyName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
