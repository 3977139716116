import React from 'react';
import './styles.scss';
import projectImage from '../../../assets/img/portfolio/weather.png';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../common/Breadcrumb';

const WeatherApp = () => {
  return (
    <>
      <Breadcrumb />
      <div className="project-container">
        <div className="project-header">
          <h1>Modern Weather Application</h1>
          <div className="project-tags">
            <span className="tag">JavaScript</span>
            <span className="tag">API</span>
            <span className="tag">CSS3</span>
            <span className="tag">HTML5</span>
          </div>
        </div>

        <div className="project-hero">
          <img src={projectImage} alt="Weather App Interface" />
        </div>

        <div className="project-content">
          <section className="overview">
            <h2>Overview</h2>
            <p>A modern weather application that delivers real-time weather conditions and forecasts 
               for cities worldwide. Built with vanilla JavaScript and featuring an intuitive interface 
               with autocomplete city search functionality.</p>
          </section>

          <section className="key-features">
            <h2>Key Features</h2>
            <ul>
              <li>Real-time weather data retrieval</li>
              <li>City search with autocomplete</li>
              <li>3-day weather forecast</li>
              <li>Responsive mobile-first design</li>
            </ul>
          </section>

          <section className="methodology">
            <h2>Methodology</h2>
            <div className="methodology-steps">
              <div className="step">
                <h3>Data Integration</h3>
                <p>Integrated wttr.in API for reliable weather data retrieval and implemented 
                   efficient data caching for improved performance.</p>
              </div>
              <div className="step">
                <h3>Search Functionality</h3>
                <p>Developed an intuitive city search with autocomplete feature using a 
                   comprehensive cities database for accurate location selection.</p>
              </div>
              <div className="step">
                <h3>User Interface</h3>
                <p>Created a clean, responsive interface with smooth animations and 
                   intuitive weather information display.</p>
              </div>
            </div>
          </section>

          <section className="results">
            <h2>Results</h2>
            <div className="metrics-grid">
              <div className="metric-card">
                <h3>Real-time</h3>
                <p>Weather Updates</p>
              </div>
              <div className="metric-card">
                <h3>3-Day</h3>
                <p>Weather Forecast</p>
              </div>
              <div className="metric-card">
                <h3>100%</h3>
                <p>Mobile Responsive</p>
              </div>
            </div>
          </section>

          <section className="project-links">
            <a href="https://github.com/jerryola1/weather-API" className="button github" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-github"></i> View Code
            </a>
            <a href="https://weather.correct.ng/" className="button paper" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-external-link"></i> Live Demo
            </a>
          </section>
        </div>
      </div>
    </>
  );
};

export default WeatherApp; 