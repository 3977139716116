import React from 'react';
import './styles.scss';
import projectImage from '../../../assets/img/portfolio/retromaths.png';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../common/Breadcrumb';

const RetroMaths = () => {
  return (
    <>
      <Breadcrumb />
      <div className="project-container">
        <div className="project-header">
          <h1>RetroMaths - Matrix Math Challenge</h1>
          <div className="project-tags">
            <span className="tag">Next.js</span>
            <span className="tag">TypeScript</span>
            <span className="tag">Game</span>
            <span className="tag">React</span>
          </div>
        </div>

        <div className="project-hero">
          <img src={projectImage} alt="RetroMaths Game Interface" />
        </div>

        <div className="project-content">
          <section className="overview">
            <h2>Overview</h2>
            <p>An interactive web-based math game built with Next.js and React, featuring a retro-style 
               matrix theme. Players are challenged with dynamic math problems across multiple difficulty 
               levels, all wrapped in an engaging Matrix-inspired interface.</p>
          </section>

          <section className="key-features">
            <h2>Key Features</h2>
            <ul>
              <li>Dynamic math problem generation</li>
              <li>Three difficulty levels</li>
              <li>Real-time scoring system</li>
              <li>Matrix-style animations</li>
            </ul>
          </section>

          <section className="methodology">
            <h2>Methodology</h2>
            <div className="methodology-steps">
              <div className="step">
                <h3>Game Design</h3>
                <p>Implemented an engaging math challenge system with progressive difficulty levels 
                   and dynamic problem generation.</p>
              </div>
              <div className="step">
                <h3>Visual Effects</h3>
                <p>Created immersive Matrix-inspired animations using React and CSS, including the 
                   iconic digital rain effect.</p>
              </div>
              <div className="step">
                <h3>User Experience</h3>
                <p>Designed an intuitive interface with real-time feedback and scoring, making 
                   math practice both challenging and enjoyable.</p>
              </div>
            </div>
          </section>

          <section className="results">
            <h2>Results</h2>
            <div className="metrics-grid">
              <div className="metric-card">
                <h3>3</h3>
                <p>Difficulty Levels</p>
              </div>
              <div className="metric-card">
                <h3>100%</h3>
                <p>Mobile Responsive</p>
              </div>
              <div className="metric-card">
                <h3>Real-time</h3>
                <p>Score Tracking</p>
              </div>
            </div>
          </section>

          <section className="project-links">
            <a href="https://github.com/jerryola1/retro-maths" className="button github" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-github"></i> View Code
            </a>
            <a href="https://retro-maths.vercel.app" className="button paper" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-external-link"></i> Live Demo
            </a>
          </section>
        </div>
      </div>
    </>
  );
};

export default RetroMaths; 