import React from 'react';
import './styles.scss';
import projectImage from '../../../assets/img/portfolio/emotion.png';
import { Link } from 'react-router-dom';

const EmotionDetection = () => {
  return (
    <div className="project-container">
      <div className="project-header">
        <h1>Twitter Emotion Detection</h1>
        <div className="project-tags">
          <span className="tag">Machine Learning</span>
          <span className="tag">NLP</span>
          <span className="tag">Deep Learning</span>
        </div>
      </div>

      <div className="project-hero">
        <img src={projectImage} alt="Emotion Detection Model Architecture" />
      </div>

      <div className="project-content">
        <section className="overview">
          <h2>Overview</h2>
          <p>A sophisticated deep learning approach for emotion mining on Twitter, focusing on the #BBNaija reality TV show. 
             The research introduces EmoConVTLSTM, a hybrid model that achieves 92.07% accuracy in emotion detection.</p>
        </section>

        <section className="key-features">
          <h2>Key Features</h2>
          <ul>
            <li>Hybrid architecture combining CNN, Transformer, and LSTM</li>
            <li>Advanced preprocessing for Twitter text analysis</li>
            <li>Geographical emotion distribution analysis</li>
            <li>Comparative evaluation with state-of-the-art models</li>
          </ul>
        </section>

        <section className="methodology">
          <h2>Methodology</h2>
          <div className="methodology-steps">
            <div className="step">
              <h3>Data Collection & Preprocessing</h3>
              <p>Utilized multiple datasets including Emotion dataset (416,809 samples) and GoEmotion dataset, 
                 with extensive preprocessing for Twitter-specific content.</p>
            </div>
            <div className="step">
              <h3>Model Architecture</h3>
              <p>EmoConVTLSTM combines convolutional layers (128 filters), transformer block (4 attention heads), 
                 and LSTM (64 units) for optimal emotion detection.</p>
            </div>
            <div className="step">
              <h3>Training & Validation</h3>
              <p>Implemented with TensorFlow, utilizing GPU acceleration and custom training loops with early stopping.</p>
            </div>
          </div>
        </section>

        <section className="results">
          <h2>Results</h2>
          <div className="metrics-grid">
            <div className="metric-card">
              <h3>92.07%</h3>
              <p>Overall Accuracy</p>
            </div>
            <div className="metric-card">
              <h3>0.92</h3>
              <p>Precision Score</p>
            </div>
            <div className="metric-card">
              <h3>90-94%</h3>
              <p>F1-Score Range</p>
            </div>
          </div>
        </section>

        <section className="project-links">
          <a href="#" className="button github">
            <i className="fa fa-github"></i> View Code
          </a>
          <a href="#" className="button paper">
            <i className="fa fa-file-text"></i> Read Paper
          </a>
        </section>
      </div>
    </div>
  );
};

export default EmotionDetection; 