import citydataagentImg from "../../assets/img/portfolio/citydataagent.webp";
import emotionImg from "../../assets/img/portfolio/emotion.png";
import removebgImg from "../../assets/img/portfolio/removebg.png";
import retromathsImg from "../../assets/img/portfolio/retromaths.png";
import weatherImg from "../../assets/img/portfolio/weather.png";
import ollamaImg from "../../assets/img/portfolio/ollama.png";
// import Image5 from "../../assets/img/portfolio/project-5.jpg";
// import Image6 from "../../assets/img/portfolio/project-6.jpg";
// import Image7 from "../../assets/img/portfolio/project-7.jpg";
// import Image8 from "../../assets/img/portfolio/project-8.jpg";
// import Image9 from "../../assets/img/portfolio/project-9.jpg";

const PortfolioData = [
  {
    id: 1,
    type: "CityDataAgent LLM Project",
    image: citydataagentImg,
    tag: ["AI", "LLM", "Data Extraction"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "City Data Extraction LLM Agent",
        client: "Research Project",
        language: "Python, GPT-4, SerpAPI",
        preview: "View Details",
        link: "/projects/citydataagent",
        fullDescription: {
          overview: `An intelligent LLM agent designed to extract city-specific metrics from web sources, 
                    achieving 87% accuracy in data extraction and 30% API cost reduction.`,
          methodology: [
            "Web Search with SerpAPI for dynamic data gathering",
            "Data Extraction using GPT-4 for natural language processing",
            "Asynchronous Operations with manual caching system",
            "Advanced data validation using IQR for outlier detection"
          ],
          technologies: [
            "Python", "GPT-4", "SerpAPI", "asyncio", "pandas",
            "aiohttp", "Pickle for caching"
          ],
          results: {
            accuracy: "87% overall accuracy in data extraction",
            performance: "50% reduction in data retrieval time",
            optimization: "30% decrease in API calls through caching",
            validation: "Implemented IQR-based outlier detection"
          },
          images: [
            // Add paths to relevant screenshots/diagrams
          ],
          githubLink: "#", // If public
          demoLink: "#"    // If available
        }
      },
    ],
  },
  {
    id: 2,
    type: "Twitter Emotion Detection",
    image: emotionImg,
    tag: ["Machine Learning", "NLP", "Deep Learning"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "BBNaija Twitter Emotion Analysis",
        client: "University Research",
        language: "Python, TensorFlow, NLP",
        preview: "View Details",
        link: "/projects/emotion-detection",
        fullDescription: {
          overview: `A deep-learning based approach for emotion mining on Twitter, focusing on the #BBNaija reality TV show. 
                    The research introduces EmoConVTLSTM, a hybrid model combining Convolutional blocks, Transformer, and LSTM, 
                    achieving 92.07% accuracy in emotion detection.`,
          methodology: [
            "Hybrid deep learning architecture combining CNN, Transformer, and LSTM",
            "Advanced data preprocessing for Twitter text analysis",
            "Comparative evaluation with DistilBert and GloVe embeddings",
            "Geographical emotion distribution analysis"
          ],
          technologies: [
            "Python", "TensorFlow", "Deep Learning", "NLP",
            "CNN", "LSTM", "Transformer", "GPU Computing"
          ],
          results: {
            accuracy: "92.07% overall accuracy in emotion detection",
            precision: "High precision score of 0.92",
            performance: "Outperformed EmotionalBERT and other baseline models",
            validation: "Consistent F1-scores between 90% and 94%"
          },
          images: [
            // Will add paths to confusion matrix, T-SNE visualization, etc.
          ],
          githubLink: "#",
          paperLink: "#"
        }
      },
    ],
  },
  {
    id: 3,
    type: "RemoveBG - AI Background Removal",
    image: removebgImg,
    tag: ["Next.js", "AI", "TypeScript"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "RemoveBG App",
        client: "Personal Project",
        language: "TypeScript, Next.js, Tailwind CSS",
        preview: "View Details",
        link: "/projects/removebg",
        fullDescription: {
          overview: `An AI-powered background removal application built with Next.js 14 that allows users to 
                    remove backgrounds from images using FAL AI technology.`,
          methodology: [
            "AI-powered background removal using FAL AI",
            "Real-time image processing and preview",
            "Client-side image optimization",
            "Responsive design with Tailwind CSS"
          ],
          technologies: [
            "Next.js 14", "TypeScript", "Tailwind CSS", 
            "FAL AI", "Framer Motion", "React"
          ],
          results: {
            features: "Seamless background removal",
            performance: "Optimized image processing",
            interface: "Responsive mobile-first design",
            ux: "Intuitive drag-and-drop interface"
          },
          githubLink: "https://github.com/jerryola1/removebg",
          demoLink: "https://removebg-virid.vercel.app"
        }
      },
    ],
  },
  {
    id: 4,
    type: "RetroMaths Game",
    image: retromathsImg,
    tag: ["Next.js", "TypeScript", "Game"],
    delayAnimation: "300",
    modalDetails: [
      {
        project: "Matrix Math Challenge",
        client: "Personal Project",
        language: "TypeScript, Next.js, Tailwind CSS",
        preview: "View Details",
        link: "/projects/retromaths",
        fullDescription: {
          overview: `An interactive web-based math game built with Next.js and React, featuring a retro-style 
                    matrix theme that challenges players with dynamic math problems of varying difficulty.`,
          methodology: [
            "Dynamic math problem generation",
            "Real-time scoring system",
            "Animated matrix rain background",
            "Responsive design with Tailwind CSS"
          ],
          technologies: [
            "Next.js", "TypeScript", "React", 
            "Tailwind CSS", "React Icons"
          ],
          results: {
            features: "Three difficulty levels",
            performance: "Real-time scoring",
            interface: "Retro matrix theme",
            ux: "Interactive gameplay"
          },
          githubLink: "https://github.com/jerryola1/retro-maths",
          demoLink: "https://retro-maths.vercel.app"
        }
      },
    ],
  },
  {
    id: 5,
    type: "Modern Weather App",
    image: weatherImg,
    tag: ["JavaScript", "API", "CSS3"],
    delayAnimation: "400",
    modalDetails: [
      {
        project: "Weather Application",
        client: "Personal Project",
        language: "JavaScript, HTML5, CSS3",
        preview: "View Details",
        link: "/projects/weather-app",
        fullDescription: {
          overview: `A sleek and responsive weather application that provides real-time weather conditions 
                    and forecasts for cities worldwide, featuring an intuitive interface and 
                    autocomplete city search functionality.`,
          methodology: [
            "Real-time weather data integration",
            "City search with autocomplete",
            "3-day weather forecast",
            "Responsive design implementation"
          ],
          technologies: [
            "JavaScript (ES6+)", "HTML5", "CSS3",
            "wttr.in API", "Font Awesome"
          ],
          results: {
            features: "Real-time weather updates",
            interface: "Intuitive city search",
            forecast: "3-day weather predictions",
            responsive: "Mobile-first design"
          },
          githubLink: "https://github.com/jerryola1/weather-API",
          demoLink: "#"
        }
      },
    ],
  },
  {
    id: 6,
    type: "OllamaVectors - AI Document Processing",
    image: ollamaImg,
    tag: ["Python", "LangChain", "AI"],
    delayAnimation: "500",
    modalDetails: [
      {
        project: "OllamaVectors",
        client: "Personal Project",
        language: "Python, LangChain, Ollama",
        preview: "View Details",
        link: "/projects/ollama-vectors",
        fullDescription: {
          overview: `An advanced document processing system that combines Ollama embeddings with 
                    LangChain for efficient text analysis and retrieval. The project showcases 
                    the integration of modern AI tools for enhanced document understanding.`,
          methodology: [
            "Integration of Ollama embeddings for text representation",
            "LangChain document processing pipeline",
            "Vector storage optimization",
            "Efficient document splitting and retrieval"
          ],
          technologies: [
            "Python", "LangChain", "Ollama",
            "Vector Embeddings", "Document Processing"
          ],
          results: {
            features: "High-quality text embeddings",
            performance: "Efficient document retrieval",
            integration: "Seamless LangChain integration",
            processing: "Advanced document handling"
          },
          githubLink: "https://github.com/jerryola1/OllamaVectors",
          demoLink: "#"
        }
      },
    ],
  },
  
  // {
  //   id: 4,
  //   type: "local project",
  //   image: Image4,
  //   tag: ["logo", "video"],
  //   delayAnimation: "0",
  //   modalDetails: [
  //     {
  //       project: "video",
  //       client: "Videohive",
  //       language: " Adobe After Effects",
  //       preview: "www.videohive.net",
  //       link: "https://www.videohive.net",
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   type: "saas project",
  //   image: Image5,
  //   tag: ["logo"],
  //   delayAnimation: "100",
  //   modalDetails: [
  //     {
  //       project: "Web Application",
  //       client: "Themeforest",
  //       language: "HTML, CSS, ReactJS",
  //       preview: "www.envato.com",
  //       link: "https://themeforest.net/item/deski-saas-software-react-template/33799794",
  //     },
  //   ],
  // },
  // {
  //   id: 6,
  //   type: "mockup project",
  //   image: Image6,
  //   tag: ["logo", "mockup"],
  //   delayAnimation: "200",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Themeforest",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.pexels.com",
  //       link: "https://www.pexels.com",
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   type: "facebook project",
  //   image: Image7,
  //   tag: ["logo"],
  //   delayAnimation: "0",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Facebook",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.facebook.com",
  //       link: "https://www.facebook.com/ibthemes",
  //     },
  //   ],
  // },
  // {
  //   id: 8,
  //   type: "dribble project",
  //   image: Image8,
  //   tag: ["graphic design"],
  //   delayAnimation: "100",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Dribbble",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.dribbble.com",
  //       link: "https://dribbble.com/ib-themes",
  //     },
  //   ],
  // },
  // {
  //   id: 9,
  //   type: "behence project",
  //   image: Image9,
  //   tag: ["graphic design", "mockup"],
  //   delayAnimation: "200",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Behance",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.behance.com",
  //       link: "https://www.behance.net/ib-themes",
  //     },
  //   ],
  // },
];

export default PortfolioData;
