import React from "react";
import img1 from "../../../assets/img/portfolio/project-1.jpg";
import img2 from "../../../assets/img/portfolio/project-2.jpg";
import portfolioVideo from "../../../assets/img/portfolio/video.mp4";
import CloseImg from "../../../assets/img/cancel.svg";
import PortfolioData from "../portfolioData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const Modal = ({ modalId, setGetModal }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
  };
  if (modalId === 1) {
    return (
      <div className="modal_portfolio ">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            const details = item.modalDetails[0];
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">{item.type}</h2>
                <div className="modal__details">
                  <div className="row open-sans-font">
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-file-text-o pr-2"></i>
                      Project:{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.project}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-user-o pr-2"></i>
                      Client :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.client}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-code pr-2"></i>
                      Language :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.language}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-external-link pr-2"></i>
                      Preview :{" "}
                      <Link
                        to={details.link}
                        className="preview-link"
                        onClick={() => setGetModal(false)}
                      >
                        {details.preview}
                      </Link>
                    </div>
                  </div>

                  {/* Full Description Section */}
                  {details.fullDescription && (
                    <div className="project-details mt-4">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3">Overview</h3>
                          <p className="open-sans-font">{details.fullDescription.overview}</p>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Methodology</h3>
                          <ul className="methodology-list">
                            {details.fullDescription.methodology.map((method, index) => (
                              <li key={index} className="open-sans-font">{method}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Technologies Used</h3>
                          <div className="tech-stack">
                            {details.fullDescription.technologies.map((tech, index) => (
                              <span key={index} className="tech-badge">{tech}</span>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Results</h3>
                          <ul className="results-list">
                            {Object.entries(details.fullDescription.results).map(([key, value]) => (
                              <li key={key} className="open-sans-font">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        </div>

                        {details.fullDescription.githubLink && (
                          <div className="col-12 mt-4">
                            <a href={details.fullDescription.githubLink} 
                               className="button" 
                               target="_blank" 
                               rel="noopener noreferrer">
                              <i className="fa fa-github"></i> View Code
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 2) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            const details = item.modalDetails[0];
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">{item.type}</h2>
                <div className="modal__details">
                  <div className="row open-sans-font">
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-file-text-o pr-2"></i>
                      Project:{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.project}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-user-o pr-2"></i>
                      Client :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.client}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-code pr-2"></i>
                      Language :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.language}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-external-link pr-2"></i>
                      Preview :{" "}
                      <Link
                        to={details.link}
                        className="preview-link"
                        onClick={() => setGetModal(false)}
                      >
                        {details.preview}
                      </Link>
                    </div>
                  </div>

                  {/* Full Description Section */}
                  {details.fullDescription && (
                    <div className="project-details mt-4">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3">Overview</h3>
                          <p className="open-sans-font">{details.fullDescription.overview}</p>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Methodology</h3>
                          <ul className="methodology-list">
                            {details.fullDescription.methodology.map((method, index) => (
                              <li key={index} className="open-sans-font">{method}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Technologies Used</h3>
                          <div className="tech-stack">
                            {details.fullDescription.technologies.map((tech, index) => (
                              <span key={index} className="tech-badge">{tech}</span>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Results</h3>
                          <ul className="results-list">
                            {Object.entries(details.fullDescription.results).map(([key, value]) => (
                              <li key={key} className="open-sans-font">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        </div>

                        {details.fullDescription.githubLink && (
                          <div className="col-12 mt-4">
                            <a href={details.fullDescription.githubLink} 
                               className="button" 
                               target="_blank" 
                               rel="noopener noreferrer">
                              <i className="fa fa-github"></i> View Code
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <figure className="modal__img videocontainer">
                  <iframe
                    src=""
                    title="YouTube video player"
                    className="youtube-video"
                    allowFullScreen
                  ></iframe>
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 3) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            const details = item.modalDetails[0];
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">{item.type}</h2>
                <div className="modal__details">
                  <div className="row open-sans-font">
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-file-text-o pr-2"></i>
                      Project:{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.project}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-user-o pr-2"></i>
                      Client :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.client}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-code pr-2"></i>
                      Language :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.language}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-external-link pr-2"></i>
                      Preview :{" "}
                      <Link
                        to={details.link}
                        className="preview-link"
                        onClick={() => setGetModal(false)}
                      >
                        {details.preview}
                      </Link>
                    </div>
                  </div>

                  {/* Full Description Section */}
                  {details.fullDescription && (
                    <div className="project-details mt-4">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3">Overview</h3>
                          <p className="open-sans-font">{details.fullDescription.overview}</p>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Methodology</h3>
                          <ul className="methodology-list">
                            {details.fullDescription.methodology.map((method, index) => (
                              <li key={index} className="open-sans-font">{method}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Technologies Used</h3>
                          <div className="tech-stack">
                            {details.fullDescription.technologies.map((tech, index) => (
                              <span key={index} className="tech-badge">{tech}</span>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Results</h3>
                          <ul className="results-list">
                            {Object.entries(details.fullDescription.results).map(([key, value]) => (
                              <li key={key} className="open-sans-font">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        </div>

                        {details.fullDescription.githubLink && (
                          <div className="col-12 mt-4">
                            <a href={details.fullDescription.githubLink} 
                               className="button" 
                               target="_blank" 
                               rel="noopener noreferrer">
                              <i className="fa fa-github"></i> View Code
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <figure className="modal__img">
                  <Slider {...settings}>
                    <div>
                      <img src={item.image} alt="portfolio project demo" />
                    </div>
                    <div>
                      <img src={img1} alt="portfolio project demo" />
                    </div>
                    <div>
                      <img src={img2} alt="portfolio project demo" />
                    </div>
                  </Slider>
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 4) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            const details = item.modalDetails[0];
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">{item.type}</h2>
                <div className="modal__details">
                  <div className="row open-sans-font">
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-file-text-o pr-2"></i>
                      Project:{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.project}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-user-o pr-2"></i>
                      Client :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.client}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-code pr-2"></i>
                      Language :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.language}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-external-link pr-2"></i>
                      Preview :{" "}
                      <Link
                        to={details.link}
                        className="preview-link"
                        onClick={() => setGetModal(false)}
                      >
                        {details.preview}
                      </Link>
                    </div>
                  </div>

                  {/* Full Description Section */}
                  {details.fullDescription && (
                    <div className="project-details mt-4">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3">Overview</h3>
                          <p className="open-sans-font">{details.fullDescription.overview}</p>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Methodology</h3>
                          <ul className="methodology-list">
                            {details.fullDescription.methodology.map((method, index) => (
                              <li key={index} className="open-sans-font">{method}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Technologies Used</h3>
                          <div className="tech-stack">
                            {details.fullDescription.technologies.map((tech, index) => (
                              <span key={index} className="tech-badge">{tech}</span>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Results</h3>
                          <ul className="results-list">
                            {Object.entries(details.fullDescription.results).map(([key, value]) => (
                              <li key={key} className="open-sans-font">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        </div>

                        {details.fullDescription.githubLink && (
                          <div className="col-12 mt-4">
                            <a href={details.fullDescription.githubLink} 
                               className="button" 
                               target="_blank" 
                               rel="noopener noreferrer">
                              <i className="fa fa-github"></i> View Code
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <figure className="modal__img">
                  {/* <img src={item.image} alt="portfolio project demo" /> */}
                  <video
                    id="video"
                    className="responsive-video"
                    controls
                    poster={item.image}
                  >
                    <source src={portfolioVideo} type="video/mp4" />
                    {/* <source src="img/" type="video/mp4" /> */}
                  </video>
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 5) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            const details = item.modalDetails[0];
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">{item.type}</h2>
                <div className="modal__details">
                  <div className="row open-sans-font">
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-file-text-o pr-2"></i>
                      Project:{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.project}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-user-o pr-2"></i>
                      Client :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.client}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-code pr-2"></i>
                      Language :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.language}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-external-link pr-2"></i>
                      Preview :{" "}
                      <Link
                        to={details.link}
                        className="preview-link"
                        onClick={() => setGetModal(false)}
                      >
                        {details.preview}
                      </Link>
                    </div>
                  </div>

                  {/* Full Description Section */}
                  {details.fullDescription && (
                    <div className="project-details mt-4">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3">Overview</h3>
                          <p className="open-sans-font">{details.fullDescription.overview}</p>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Methodology</h3>
                          <ul className="methodology-list">
                            {details.fullDescription.methodology.map((method, index) => (
                              <li key={index} className="open-sans-font">{method}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Technologies Used</h3>
                          <div className="tech-stack">
                            {details.fullDescription.technologies.map((tech, index) => (
                              <span key={index} className="tech-badge">{tech}</span>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Results</h3>
                          <ul className="results-list">
                            {Object.entries(details.fullDescription.results).map(([key, value]) => (
                              <li key={key} className="open-sans-font">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        </div>

                        {details.fullDescription.githubLink && (
                          <div className="col-12 mt-4">
                            <a href={details.fullDescription.githubLink} 
                               className="button" 
                               target="_blank" 
                               rel="noopener noreferrer">
                              <i className="fa fa-github"></i> View Code
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 6) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            const details = item.modalDetails[0];
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">{item.type}</h2>
                <div className="modal__details">
                  <div className="row open-sans-font">
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-file-text-o pr-2"></i>
                      Project:{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.project}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-user-o pr-2"></i>
                      Client :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.client}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-code pr-2"></i>
                      Language :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.language}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-external-link pr-2"></i>
                      Preview :{" "}
                      <Link
                        to={details.link}
                        className="preview-link"
                        onClick={() => setGetModal(false)}
                      >
                        {details.preview}
                      </Link>
                    </div>
                  </div>

                  {/* Full Description Section */}
                  {details.fullDescription && (
                    <div className="project-details mt-4">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3">Overview</h3>
                          <p className="open-sans-font">{details.fullDescription.overview}</p>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Methodology</h3>
                          <ul className="methodology-list">
                            {details.fullDescription.methodology.map((method, index) => (
                              <li key={index} className="open-sans-font">{method}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Technologies Used</h3>
                          <div className="tech-stack">
                            {details.fullDescription.technologies.map((tech, index) => (
                              <span key={index} className="tech-badge">{tech}</span>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Results</h3>
                          <ul className="results-list">
                            {Object.entries(details.fullDescription.results).map(([key, value]) => (
                              <li key={key} className="open-sans-font">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        </div>

                        {details.fullDescription.githubLink && (
                          <div className="col-12 mt-4">
                            <a href={details.fullDescription.githubLink} 
                               className="button" 
                               target="_blank" 
                               rel="noopener noreferrer">
                              <i className="fa fa-github"></i> View Code
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 7) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            const details = item.modalDetails[0];
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">{item.type}</h2>
                <div className="modal__details">
                  <div className="row open-sans-font">
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-file-text-o pr-2"></i>
                      Project:{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.project}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-user-o pr-2"></i>
                      Client :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.client}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-code pr-2"></i>
                      Language :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.language}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-external-link pr-2"></i>
                      Preview :{" "}
                      <Link
                        to={details.link}
                        className="preview-link"
                        onClick={() => setGetModal(false)}
                      >
                        {details.preview}
                      </Link>
                    </div>
                  </div>

                  {/* Full Description Section */}
                  {details.fullDescription && (
                    <div className="project-details mt-4">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3">Overview</h3>
                          <p className="open-sans-font">{details.fullDescription.overview}</p>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Methodology</h3>
                          <ul className="methodology-list">
                            {details.fullDescription.methodology.map((method, index) => (
                              <li key={index} className="open-sans-font">{method}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Technologies Used</h3>
                          <div className="tech-stack">
                            {details.fullDescription.technologies.map((tech, index) => (
                              <span key={index} className="tech-badge">{tech}</span>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Results</h3>
                          <ul className="results-list">
                            {Object.entries(details.fullDescription.results).map(([key, value]) => (
                              <li key={key} className="open-sans-font">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        </div>

                        {details.fullDescription.githubLink && (
                          <div className="col-12 mt-4">
                            <a href={details.fullDescription.githubLink} 
                               className="button" 
                               target="_blank" 
                               rel="noopener noreferrer">
                              <i className="fa fa-github"></i> View Code
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 8) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            const details = item.modalDetails[0];
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">{item.type}</h2>
                <div className="modal__details">
                  <div className="row open-sans-font">
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-file-text-o pr-2"></i>
                      Project:{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.project}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-user-o pr-2"></i>
                      Client :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.client}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-code pr-2"></i>
                      Language :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.language}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-external-link pr-2"></i>
                      Preview :{" "}
                      <Link
                        to={details.link}
                        className="preview-link"
                        onClick={() => setGetModal(false)}
                      >
                        {details.preview}
                      </Link>
                    </div>
                  </div>

                  {/* Full Description Section */}
                  {details.fullDescription && (
                    <div className="project-details mt-4">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3">Overview</h3>
                          <p className="open-sans-font">{details.fullDescription.overview}</p>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Methodology</h3>
                          <ul className="methodology-list">
                            {details.fullDescription.methodology.map((method, index) => (
                              <li key={index} className="open-sans-font">{method}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Technologies Used</h3>
                          <div className="tech-stack">
                            {details.fullDescription.technologies.map((tech, index) => (
                              <span key={index} className="tech-badge">{tech}</span>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Results</h3>
                          <ul className="results-list">
                            {Object.entries(details.fullDescription.results).map(([key, value]) => (
                              <li key={key} className="open-sans-font">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        </div>

                        {details.fullDescription.githubLink && (
                          <div className="col-12 mt-4">
                            <a href={details.fullDescription.githubLink} 
                               className="button" 
                               target="_blank" 
                               rel="noopener noreferrer">
                              <i className="fa fa-github"></i> View Code
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (modalId === 9) {
    return (
      <div className="modal_portfolio">
        <div
          className="modal__outside"
          onClick={() => setGetModal(false)}
        ></div>
        <div className="modal__content">
          {PortfolioData.filter((item) => item.id === modalId).map((item) => {
            const details = item.modalDetails[0];
            return (
              <div key={item.id} data-aos="fade">
                <h2 className="heading mb-2">{item.type}</h2>
                <div className="modal__details">
                  <div className="row open-sans-font">
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-file-text-o pr-2"></i>
                      Project:{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.project}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-user-o pr-2"></i>
                      Client :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.client}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-code pr-2"></i>
                      Language :{" "}
                      <span className="ft-wt-600 uppercase">
                        {details.language}
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 mb-2">
                      <i className="fa fa-external-link pr-2"></i>
                      Preview :{" "}
                      <Link
                        to={details.link}
                        className="preview-link"
                        onClick={() => setGetModal(false)}
                      >
                        {details.preview}
                      </Link>
                    </div>
                  </div>

                  {/* Full Description Section */}
                  {details.fullDescription && (
                    <div className="project-details mt-4">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="mb-3">Overview</h3>
                          <p className="open-sans-font">{details.fullDescription.overview}</p>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Methodology</h3>
                          <ul className="methodology-list">
                            {details.fullDescription.methodology.map((method, index) => (
                              <li key={index} className="open-sans-font">{method}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Technologies Used</h3>
                          <div className="tech-stack">
                            {details.fullDescription.technologies.map((tech, index) => (
                              <span key={index} className="tech-badge">{tech}</span>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 mt-4">
                          <h3 className="mb-3">Results</h3>
                          <ul className="results-list">
                            {Object.entries(details.fullDescription.results).map(([key, value]) => (
                              <li key={key} className="open-sans-font">
                                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                              </li>
                            ))}
                          </ul>
                        </div>

                        {details.fullDescription.githubLink && (
                          <div className="col-12 mt-4">
                            <a href={details.fullDescription.githubLink} 
                               className="button" 
                               target="_blank" 
                               rel="noopener noreferrer">
                              <i className="fa fa-github"></i> View Code
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <figure className="modal__img">
                  <img src={item.image} alt="portfolio project demo" />
                </figure>

                <button
                  className="close-modal"
                  onClick={() => setGetModal(false)}
                >
                  <img src={CloseImg} alt="portfolio project demo" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default Modal;
