import React from 'react';
import './styles.scss';
import projectImage from '../../../assets/img/portfolio/removebg.png';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../common/Breadcrumb';

const RemoveBG = () => {
  return (
    <>
      <Breadcrumb />
      <div className="project-container">
        <div className="project-header">
          <h1>RemoveBG - AI Background Removal</h1>
          <div className="project-tags">
            <span className="tag">Next.js</span>
            <span className="tag">AI</span>
            <span className="tag">TypeScript</span>
            <span className="tag">Tailwind CSS</span>
          </div>
        </div>

        <div className="project-hero">
          <img src={projectImage} alt="RemoveBG Application Interface" />
        </div>

        <div className="project-content">
          <section className="overview">
            <h2>Overview</h2>
            <p>A modern web application that leverages AI technology to remove backgrounds from images. 
               Built with Next.js 14 and TypeScript, it provides an intuitive interface for users to 
               upload, process, and download images with transparent backgrounds.</p>
          </section>

          <section className="key-features">
            <h2>Key Features</h2>
            <ul>
              <li>AI-powered background removal using FAL AI</li>
              <li>Drag-and-drop image upload</li>
              <li>Real-time image preview</li>
              <li>Responsive design for all devices</li>
            </ul>
          </section>

          <section className="methodology">
            <h2>Methodology</h2>
            <div className="methodology-steps">
              <div className="step">
                <h3>Frontend Development</h3>
                <p>Built with Next.js 14 and TypeScript, utilizing Tailwind CSS for responsive design 
                   and Framer Motion for smooth animations.</p>
              </div>
              <div className="step">
                <h3>Image Processing</h3>
                <p>Integrated FAL AI service for high-quality background removal, with optimized 
                   image handling and processing.</p>
              </div>
              <div className="step">
                <h3>User Experience</h3>
                <p>Implemented intuitive drag-and-drop functionality and image preview features 
                   for seamless user interaction.</p>
              </div>
            </div>
          </section>

          <section className="results">
            <h2>Results</h2>
            <div className="metrics-grid">
              <div className="metric-card">
                <h3>100%</h3>
                <p>Mobile Responsive</p>
              </div>
              <div className="metric-card">
                <h3>AI</h3>
                <p>Powered Processing</p>
              </div>
              <div className="metric-card">
                <h3>Fast</h3>
                <p>Image Processing</p>
              </div>
            </div>
          </section>

          <section className="project-links">
            <a href="https://github.com/jerryola1/removebg" className="button github" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-github"></i> View Code
            </a>
            <a href="https://rbg.correct.ng/" className="button paper" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-external-link"></i> Live Demo
            </a>
          </section>
        </div>
      </div>
    </>
  );
};

export default RemoveBG; 