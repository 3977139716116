import React from 'react';
import './styles.scss';
// Import image from the correct path within src
import projectImage from '../../../assets/img/portfolio/citydataagent.webp';

const CityDataAgent = () => {
  return (
    <div className="project-container">
      <div className="project-header">
        <h1>CityDataAgent LLM Project</h1>
        <div className="project-tags">
          <span className="tag">AI</span>
          <span className="tag">LLM</span>
          <span className="tag">Data Extraction</span>
        </div>
      </div>

      <div className="project-hero">
        <img src={projectImage} alt="CityDataAgent Dashboard" />
      </div>

      <div className="project-content">
        <section className="overview">
          <h2>Overview</h2>
          <p>An intelligent LLM agent designed to extract city-specific metrics from web sources, 
             achieving 87% accuracy in data extraction and 30% API cost reduction.</p>
        </section>

        <section className="key-features">
          <h2>Key Features</h2>
          <ul>
            <li>Dynamic web search using SerpAPI</li>
            <li>GPT-4 powered data extraction</li>
            <li>Asynchronous operations with caching</li>
            <li>Advanced IQR-based data validation</li>
          </ul>
        </section>

        <section className="methodology">
          <h2>Methodology</h2>
          <div className="methodology-steps">
            <div className="step">
              <h3>Data Collection</h3>
              <p>Implemented SerpAPI for dynamic web searching and data gathering, ensuring real-time 
                 access to city-specific information.</p>
            </div>
            <div className="step">
              <h3>Data Processing</h3>
              <p>Utilized GPT-4 for natural language processing and data extraction, with custom 
                 prompts for optimal accuracy.</p>
            </div>
            <div className="step">
              <h3>Validation & Optimization</h3>
              <p>Developed IQR-based outlier detection and implemented manual caching system for 
                 improved performance.</p>
            </div>
          </div>
        </section>

        <section className="results">
          <h2>Results</h2>
          <div className="metrics-grid">
            <div className="metric-card">
              <h3>87%</h3>
              <p>Data Extraction Accuracy</p>
            </div>
            <div className="metric-card">
              <h3>50%</h3>
              <p>Reduction in Retrieval Time</p>
            </div>
            <div className="metric-card">
              <h3>30%</h3>
              <p>API Cost Reduction</p>
            </div>
          </div>
        </section>

        <section className="project-links">
          <a href="https://github.com/..." className="button github">
            <i className="fa fa-github"></i> View Code
          </a>
          <a href="https://demo-url.com" className="button paper">
            <i className="fa fa-file-text"></i> Read Paper
          </a>
        </section>
      </div>
    </div>
  );
};

export default CityDataAgent; 