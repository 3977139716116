import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Abayomi" },
  { meta: "last name", metaInfo: "Olagunju" },
  { meta: "Specialization", metaInfo: "AI & Machine Learning" },
  { meta: "Nationality", metaInfo: "Nigerian" },
  { meta: "Freelance", metaInfo: <span style={{ color: 'green' }}>Available</span> },
  { meta: "Location", metaInfo: "Hull, United Kingdom" },
  { meta: "phone", metaInfo: "+447440608919" },
  { meta: "Email", metaInfo: "info@abayomiolagunju.net" },
  { meta: "Research", metaInfo: "Large Language Models • Natural Language Processing • Computer Vision • Medical Image Analysis • Object Detection & Annotation" },
  { meta: "Languages", metaInfo: "Yoruba, English" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
