import React from 'react';
import './styles.scss';
import projectImage from '../../../assets/img/portfolio/ollama.png';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../common/Breadcrumb';

const OllamaVectors = () => {
  return (
    <>
      <Breadcrumb />
      <div className="project-container">
        <div className="project-header">
          <h1>OllamaVectors - AI Document Processing</h1>
          <div className="project-tags">
            <span className="tag">Python</span>
            <span className="tag">LangChain</span>
            <span className="tag">AI</span>
            <span className="tag">Vector Embeddings</span>
          </div>
        </div>

        <div className="project-hero">
          <img src={projectImage} alt="OllamaVectors System Architecture" />
        </div>

        <div className="project-content">
          <section className="overview">
            <h2>Overview</h2>
            <p>An innovative document processing system that leverages Ollama embeddings and LangChain 
               for sophisticated text analysis and retrieval. This project demonstrates the power of 
               combining modern AI tools for enhanced document understanding and processing.</p>
          </section>

          <section className="key-features">
            <h2>Key Features</h2>
            <ul>
              <li>High-quality Ollama embeddings integration</li>
              <li>Efficient document splitting and processing</li>
              <li>Vector-based similarity search</li>
              <li>LangChain ecosystem integration</li>
            </ul>
          </section>

          <section className="methodology">
            <h2>Methodology</h2>
            <div className="methodology-steps">
              <div className="step">
                <h3>Embedding Generation</h3>
                <p>Implemented Ollama's embedding system for creating high-dimensional 
                   vector representations of text documents.</p>
              </div>
              <div className="step">
                <h3>Document Processing</h3>
                <p>Utilized LangChain's document processing capabilities for efficient 
                   text splitting and analysis.</p>
              </div>
              <div className="step">
                <h3>Vector Storage</h3>
                <p>Developed an optimized vector storage system for quick and 
                   accurate document retrieval.</p>
              </div>
            </div>
          </section>

          <section className="results">
            <h2>Results</h2>
            <div className="metrics-grid">
              <div className="metric-card">
                <h3>Efficient</h3>
                <p>Document Processing</p>
              </div>
              <div className="metric-card">
                <h3>Advanced</h3>
                <p>Text Analysis</p>
              </div>
              <div className="metric-card">
                <h3>Seamless</h3>
                <p>AI Integration</p>
              </div>
            </div>
          </section>

          <section className="project-links">
            <a href="https://github.com/jerryola1/OllamaVectors" className="button github" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-github"></i> View Code
            </a>
            <a href="#" className="button paper" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-file-text"></i> Documentation
            </a>
          </section>
        </div>
      </div>
    </>
  );
};

export default OllamaVectors; 