import React from "react";

const skillsContent = [
  // AI & ML Skills
  { skillClass: "p95", skillPercent: "95", skillName: "MACHINE LEARNING" },
  { skillClass: "p90", skillPercent: "90", skillName: "DEEP LEARNING" },
  { skillClass: "p88", skillPercent: "88", skillName: "NLP" },
  { skillClass: "p85", skillPercent: "85", skillName: "COMPUTER VISION" },
  { skillClass: "p90", skillPercent: "90", skillName: "LANGCHAIN/RAG" },
  
  // Programming & Tools
  { skillClass: "p92", skillPercent: "92", skillName: "PYTHON" },
  { skillClass: "p85", skillPercent: "85", skillName: "TENSORFLOW" },
  { skillClass: "p85", skillPercent: "85", skillName: "PYTORCH" },
  { skillClass: "p80", skillPercent: "80", skillName: "SCIKIT-LEARN" },
  
  // Data Analysis
  { skillClass: "p89", skillPercent: "89", skillName: "POWER BI" },
  { skillClass: "p85", skillPercent: "85", skillName: "DATA ANALYSIS" },
  { skillClass: "p82", skillPercent: "82", skillName: "SQL" },
  { skillClass: "p80", skillPercent: "80", skillName: "EXCEL" },
  
  // Web Development
  { skillClass: "p75", skillPercent: "75", skillName: "REACT" },
  { skillClass: "p70", skillPercent: "70", skillName: "NODEJS" },
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 ${val.skillClass}`}>
            <span>{val.skillPercent}%</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
