import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';

const Breadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname.split('/').filter(segment => segment);

  const handleProjectsClick = (e) => {
    e.preventDefault();
    navigate('/', { state: { scrollToPortfolio: true } });
    
    // Add a small delay to ensure navigation completes before scrolling
    setTimeout(() => {
      const portfolioSection = document.getElementById('portfolio');
      if (portfolioSection) {
        portfolioSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <nav className="breadcrumb-nav">
      <div className="breadcrumb-container">
        <Link to="/" className="breadcrumb-item">
          <i className="fa fa-home"></i> Home
        </Link>
        {pathSegments.map((segment, index) => {
          // Special handling for "projects" segment
          if (segment === "projects" && index === 0) {
            return (
              <React.Fragment key={segment}>
                <span className="breadcrumb-separator">/</span>
                <button 
                  className="breadcrumb-item"
                  onClick={handleProjectsClick}
                >
                  Projects
                </button>
              </React.Fragment>
            );
          }

          const path = `/${pathSegments.slice(0, index + 1).join('/')}`;
          const formattedName = segment
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          return (
            <React.Fragment key={path}>
              <span className="breadcrumb-separator">/</span>
              <Link 
                to={path}
                className={`breadcrumb-item ${
                  index === pathSegments.length - 1 ? 'active' : ''
                }`}
              >
                {formattedName}
              </Link>
            </React.Fragment>
          );
        })}
      </div>
    </nav>
  );
};

export default Breadcrumb; 