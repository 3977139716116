import React, { useEffect } from "react";
import ScrollToTop from "./components/ScrollToTop";
import AllRoutes from "./router/AllRoutes";
import AOS from "aos";
import "aos/dist/aos.css";
import AnimatedCursor from "react-animated-cursor";
import { ToastContainer } from "react-toastify";
import CityDataAgent from "./components/projects/CityDataAgent";
import EmotionDetection from "./components/projects/EmotionDetection";
import RemoveBG from "./components/projects/RemoveBG";
import RetroMaths from "./components/projects/RetroMaths";
import WeatherApp from "./components/projects/WeatherApp";
import OllamaVectors from "./components/projects/OllamaVectors";
import { Routes, Route } from "react-router-dom";

const App = () => {
  // this for animation
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="255, 160, 1"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<AllRoutes />} />
        <Route path="/projects/citydataagent" element={<CityDataAgent />} />
        <Route path="/projects/emotion-detection" element={<EmotionDetection />} />
        <Route path="/projects/removebg" element={<RemoveBG />} />
        <Route path="/projects/retromaths" element={<RetroMaths />} />
        <Route path="/projects/weather-app" element={<WeatherApp />} />
        <Route path="/projects/ollama-vectors" element={<OllamaVectors />} />
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
